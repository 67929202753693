import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "of-punks-and-python-libraries",
      "style": {
        "position": "relative"
      }
    }}>{`Of Punks and Python Libraries`}<a parentName="h1" {...{
        "href": "#of-punks-and-python-libraries",
        "aria-label": "of punks and python libraries permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`In this session, we'll take a look at a live dataset on Ocean (the cryptopunks images) and will discuss the legitimacy of various kinds of data. Then we'll dive into the Ocean Python library and speak a bit about private keys, sample data and how to simplify all the steps we'll walk through for those who follow us.`}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ul>
      <li parentName="ul">{`Take a look at the `}<a parentName="li" {...{
          "href": "https://market.oceanprotocol.com/asset/did:op:C9D0568838fa670baEe7195Ea443b32EfCAc2281"
        }}>{`cryptopunks dataset on Ocean`}</a>{`.`}</li>
      <li parentName="ul">{`(Stretch goal) consider how `}<a parentName="li" {...{
          "href": "https://arxiv.org/abs/2001.06684"
        }}>{`data scientists collaborate`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "recording",
      "style": {
        "position": "relative"
      }
    }}>{`Recording`}<a parentName="h2" {...{
        "href": "#recording",
        "aria-label": "recording permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Video src="https://www.youtube-nocookie.com/embed/su37df1GW88" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      